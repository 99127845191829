<template>
  <button
    class="btn normal-case px-4 leading-tight min-h-0 py-1 gap-2 flex items-center  rounded-xl"
    :class="getStyles()"
    @click="handleClick()"
    :disabled="disabled"
  >
	<template v-if="icon">
		<font-awesome-icon class="-ml-2 mr-2 text-lg" :icon="icon"></font-awesome-icon>
	</template>
  <span v-if="loading" class="loader w-5 h-5 flex items-center" ></span>
  <span class="capitalize font-bold  tracking-wide" v-text="text" />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: " ",
    },
    type: {
      type: String,
      default: " ",
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
    outline: {
      default: false,
      type: Boolean,
    },
    size: {
      default: " ",
      type: String,
    },
    height: {
      default: "10",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    cirlce: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: "",
      type: String,
    },
    loader: {
      default: false,
      type: Boolean,
    },
    customClass: {
      default: "",
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    }
  },
  methods: {
    getStyles() {
      let classes = " ";
      if (this.type) classes += ` btn-${this.type} `;
      if (this.type === 'primary') classes +=   ' focus:bg-primary-600 ' 
      if (this.outline) classes += ` btn-outline border-2 `;
      if (this.size) {
        classes += ` btn-${this.size} `;
        if (this.size === 'sm') classes +=  '  py-2 '
      }
      if (this.cirlce) classes += ` btn-circle `;
      if (this.loader) classes += " loading ";
      if (this.disabled) classes += " bg-gray-300 ";
      classes += " w-32 ";
      classes += `h-${this.height}`
      return classes + " " + this.customClass;
    },
    handleClick() {
      if (this.disabled) return;
      this.$emit("click");
    },
  },
};
</script>
